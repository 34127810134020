html, body { padding: 0; margin: 0;} 


.font-roboto {
    font-family: 'Roboto', sans-serif;
  }
  
  .font-plus-jakarta-sans {
    font-family: 'Plus Jakarta Sans', sans-serif;
  }

  .img-responsive {
    width: 100%;
    height: 100%;
  }

  .custom-font-size-13 {
    font-size: 13px;
}

.custom-font-size-14 {
  font-size: 14px;
}

.custom-font-size-12 {
  font-size: 12px;
}

.custom-font-size-11 {
  font-size: 11px;
}

.custom-font-size-10 {
  font-size: 10px;
}

.custom-zindex-4 {
  z-index: 4 !important;
}

.custom-margin-top-8rem{
  margin-top: 8rem;
}

.custom-margin-top-negative-2rem {
  margin-top: -2rem;
}

.custom-margin-top-negative-1rem {
  margin-top: -1rem;
}

.custom-margin-top-negative-0P7rem {
  margin-top: -0.7rem;
}

.custom-margin-left-negative-2rem {
  margin-left: -2rem;
}

.changePlanPopUp {
  z-index: 10 !important;
  float: right;
  margin-right: -1vw;
  margin-top: -2vh;
  font-family: "Roboto", Helvetica;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  background: white;
  border-radius: 10px;
  padding: 10px;
  transition: opacity 0.5s ease-in-out;
}

.custom-margin-4rem {
  margin-top: 4rem !important;
}

.custom-bd-top-light-blue {
  border-top: 1px solid #E6EFFD !important;
}
.custom-bd-top-light-blue-2 {
  border-top: 2px solid #E6EFFD !important;
}
.custom-bd-left-light-blue {
  border-left: 1px solid #E6EFFD !important;
}

.custom-bd-left-light-blue-2 {
  border-left: 2px solid #E6EFFD !important;
}

.custom-bg-darkBlue {
  background: #00378B !important;
}

.custom-bg-mediumBlue {
  background: #80ADF3 !important;
}

.custom-bg-lightBlue {
  background: #E1EDFF !important;
}

.custom-color-darkBlue {
  color: #00378B;
}

.custom-color-mediumBlue {
  color: #80ADF3;
}

.custom-color-lightBlue {
  color: #E1EDFF;
}


.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #909090 !important;
}


.modal {
  background-color: hsl(0, 0%, 40%, 0.3) !important;
}

.custom-cursor-pointer {
  cursor: pointer;
}